// frontend/src/components/PageBuilder/PageBuilder.js

import React, { useContext, useState, useEffect } from 'react';
import styles from './PageBuilder.module.css';
import Sidebar from '../Sidebar/Sidebar';
import SidebarContext from '../../contexts/SidebarContext';
import GrapesJSEditor from './GrapesJSEditor';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

// Import child components
import TemplateSelection from './TemplateSelection';
import PageList from './PageList';
import EditorHeader from './EditorHeader';
import CreatePageModal from './modals/CreatePageModal';
import SaveTemplateModal from './modals/SaveTemplateModal';
import ConfirmOverwriteModal from './modals/ConfirmOverwriteModal';
import DeploymentModal from './modals/DeploymentModal';
import DeleteConfirmationModal from './modals/DeleteConfirmationModal';

// Utility functions
import { removeIdsFromHtml } from '../../utils/removeIds';

const PageBuilder = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  const [pages, setPages] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [currentMode, setCurrentMode] = useState('selectTemplate');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [userTemplates, setUserTemplates] = useState([]);
  const [templatesData, setTemplatesData] = useState([]);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [userSettings, setUserSettings] = useState(null);
  const [deployData, setDeployData] = useState({
    ipAddress: '',
    username: '',
    password: '',
    osVersion: 'ubuntu20.04',
    templateName: '',
  });
  const [isDeploying, setIsDeploying] = useState(false);
  const [saveTemplateName, setSaveTemplateName] = useState('');
  const [isConfirmOverwriteOpen, setConfirmOverwriteOpen] = useState(false);
  const navigate = useNavigate();

  // Modals State
  const [isCreatePageModalOpen, setCreatePageModalOpen] = useState(false);
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isDeployModalOpen, setDeployModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  // Get backend URL from environment variables
  const backendBaseUrl = process.env.REACT_APP_BACKEND_URL || '';

  useEffect(() => {
    // Fetch user templates
    const fetchUserTemplates = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found in localStorage.');
          toast.error('You need to be logged in to access your templates.');
          return;
        }

        const response = await axios.get(`${backendBaseUrl}/api/user-templates`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserTemplates(response.data);
      } catch (error) {
        console.error('Error fetching user templates:', error);
        toast.error('Failed to fetch your saved templates.');
      }
    };

    fetchUserTemplates();
  }, [backendBaseUrl]);

  useEffect(() => {
    // Fetch user settings
    const fetchUserSettings = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found in localStorage.');
          toast.error('You need to be logged in.');
          navigate('/login');
          return;
        }

        const response = await axios.get(`${backendBaseUrl}/api/user-settings`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUserSettings(response.data);
      } catch (error) {
        console.error('Error fetching user settings:', error);
        toast.error('Failed to fetch user settings.');
      }
    };

    fetchUserSettings();
  }, [backendBaseUrl, navigate]);

  // Functions to handle modals
  const openCreatePageModal = () => setCreatePageModalOpen(true);
  const closeCreatePageModal = () => setCreatePageModalOpen(false);

  const openSaveModal = () => setSaveModalOpen(true);
  const closeSaveModal = () => setSaveModalOpen(false);

  const openDeployModal = () => setDeployModalOpen(true);
  const closeDeployModal = () => setDeployModalOpen(false);

  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  // Function to handle creating a new page
  const handleCreateNewPage = async (newPageData) => {
    const { newPageName, newPageType, selectedFile, pageToCloneId, newPageIsHidden } = newPageData;

    if (newPageName.trim() === '') {
      toast.error('Please enter a page name.');
      return;
    }

    const newPage = {
      id: Date.now().toString() + Math.random().toString(),
      name: newPageName.trim(),
      htmlContent: '',
      cssContent: '',
      headContent: '',
      isHidden: newPageIsHidden,
    };

    if (newPageType === 'blank') {
      // No additional action needed, htmlContent is empty
      setPages([...pages, newPage]);
      setSelectedPageId(newPage.id);
      toast.success(`Page "${newPage.name}" created successfully.`);
      closeCreatePageModal();
    } else if (newPageType === 'loadHtml') {
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;

          // Parse the HTML content to extract head, body, styles, and scripts
          const parser = new DOMParser();
          const doc = parser.parseFromString(content, 'text/html');

          // Extract head content and remove IDs
          let headContent = doc.head.innerHTML || '';
          headContent = removeIdsFromHtml(headContent);

          // Extract body content and remove IDs
          let bodyContent = doc.body.innerHTML || '';
          bodyContent = removeIdsFromHtml(bodyContent);

          // Extract styles from both head and body
          let styles = '';

          // Extract styles from head
          const headStyleTags = doc.head.getElementsByTagName('style');
          for (let styleTag of headStyleTags) {
            styles += styleTag.innerHTML;
          }

          // Extract styles from body
          const bodyStyleTags = doc.body.getElementsByTagName('style');
          for (let styleTag of bodyStyleTags) {
            styles += styleTag.innerHTML;
          }

          // Extract script tags from both head and body
          let scripts = '';

          // Extract scripts from head
          const headScriptTags = doc.head.getElementsByTagName('script');
          for (let scriptTag of headScriptTags) {
            scripts += scriptTag.outerHTML;
          }

          // Extract scripts from body
          const bodyScriptTags = doc.body.getElementsByTagName('script');
          for (let scriptTag of bodyScriptTags) {
            scripts += scriptTag.outerHTML;
          }

          // Combine body content and scripts
          newPage.headContent = headContent;
          newPage.htmlContent = bodyContent + scripts;
          newPage.cssContent = styles;

          // Initialize components and styles to null
          newPage.components = null;
          newPage.styles = null;

          setPages([...pages, newPage]);
          setSelectedPageId(newPage.id);
          toast.success(`Page "${newPage.name}" loaded from HTML successfully.`);
          closeCreatePageModal();
        };
        reader.readAsText(selectedFile);
      } else {
        toast.error('Please select an HTML file to load.');
      }
    } else if (newPageType === 'clonePage') {
      const pageToClone = pages.find((page) => page.id === pageToCloneId);
      if (pageToClone) {
        // Remove 'id's from cloned htmlContent and headContent
        newPage.htmlContent = removeIdsFromHtml(pageToClone.htmlContent);
        newPage.headContent = removeIdsFromHtml(pageToClone.headContent);
        newPage.cssContent = pageToClone.cssContent;

        // Deep clone components and styles
        newPage.components = pageToClone.components
          ? JSON.parse(JSON.stringify(pageToClone.components))
          : null;
        newPage.styles = pageToClone.styles
          ? JSON.parse(JSON.stringify(pageToClone.styles))
          : null;

        setPages([...pages, newPage]);
        setSelectedPageId(newPage.id);
        toast.success(`Page "${newPage.name}" cloned successfully.`);
        closeCreatePageModal();
      } else {
        toast.error('Please select a page to clone.');
      }
    }
  };

  // Function to handle saving the template
  const handleSaveTemplate = async (templateName) => {
    if (!templateName) {
      toast.error('Template name is missing.');
      return;
    }

    if (templateName.trim() === '') {
      toast.error('Template name cannot be empty.');
      return;
    }

    setIsSavingTemplate(true);

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('Token not found in localStorage');
        toast.error('You need to be logged in to save templates.');
        return;
      }

      // Prepare pages data with component attributes
      const pagesToSave = pages.map((page, index) => ({
        name: page.name,
        htmlContent: page.htmlContent,
        cssContent: page.cssContent || '',
        headContent: page.headContent || '',
        components: page.components || null,
        styles: page.styles || null,
        isHidden: page.isHidden || false,
        sequence: index,
      }));

      await axios.post(
        `${backendBaseUrl}/api/user-templates`,
        { name: templateName.trim(), pages: pagesToSave },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success('Template saved to your account.');

      // Refresh the user templates
      const fetchResponse = await axios.get(`${backendBaseUrl}/api/user-templates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserTemplates(fetchResponse.data);

      // Update selectedTemplate name
      setSelectedTemplate((prevTemplate) => ({
        ...prevTemplate,
        name: templateName.trim(),
      }));

      // Update deployData templateName
      setDeployData((prevData) => ({
        ...prevData,
        templateName: templateName.trim(),
      }));

      closeSaveModal();
    } catch (error) {
      console.error('Error saving template:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Failed to save template: ${error.response.data.error}`);
      } else {
        toast.error('Failed to save template.');
      }
    } finally {
      setIsSavingTemplate(false);
    }
  };

  const proceedWithSave = () => {
    setConfirmOverwriteOpen(false);
    handleSaveTemplate(saveTemplateName);
  };

  // Function to select a user template and load its pages
  const selectUserTemplate = async (template) => {
    try {
      setIsLoadingTemplate(true);
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('Token not found in localStorage');
        toast.error('Session expired. Please log in again.');
        return;
      }

      const templateId = template._id || template.id;

      const response = await axios.get(`${backendBaseUrl}/api/user-templates/${templateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        console.error('Failed to fetch template:', response);
        toast.error('Failed to load the selected template.');
        return;
      }

      const fullTemplate = response.data;

      // Map the pages to the required format
      const newPages = fullTemplate.pages.map((page) => ({
        id: page._id || page.id,
        name: page.name,
        htmlContent: removeIdsFromHtml(page.content),
        headContent: removeIdsFromHtml(page.headContent || ''),
        cssContent: page.cssContent || '',
        components: page.components || null,
        styles: page.styles || null,
        isHidden: page.isHidden || false,
      }));

      setPages(newPages);
      setSelectedTemplate({ ...fullTemplate, type: 'user' });
      setCurrentMode('editPage');
      if (newPages.length > 0) {
        setSelectedPageId(newPages[0].id);
      }
      toast.success(`Template "${fullTemplate.name}" loaded successfully.`);
    } catch (error) {
      console.error('Error fetching user template details:', error);
      toast.error('Failed to load the selected template.');
    } finally {
      setIsLoadingTemplate(false);
    }
  };

  const createBlankTemplate = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage.');
      toast.error('You need to be logged in.');
      navigate('/login');
      return;
    }

    if (
      !userSettings ||
      !userSettings.membershipStatus ||
      userSettings.membershipStatus.daysLeft === undefined
    ) {
      toast.error('User settings not loaded. Please try again.');
      return;
    }

    if (userSettings.membershipStatus.daysLeft <= 0) {
      toast.error('You need an active membership to create a blank template.');
      navigate('/membership');
      return;
    }

    const blankPage = {
      id: Date.now().toString() + Math.random().toString(),
      name: 'Home',
      htmlContent: '',
      cssContent: '',
      isHidden: false,
    };
    const blankTemplate = { name: 'Untitled Template', type: 'blank' };
    setSelectedTemplate(blankTemplate);
    setPages([blankPage]);
    setSelectedPageId(blankPage.id);
    setCurrentMode('editPage');
    toast.success('Blank template created. You can start editing now.');
  };

  const selectPage = (id) => {
    setSelectedPageId(id);
  };

  const updatePageContent = (id, html, css, headContent, components, styles) => {
    setPages((prevPages) => {
      const pageIndex = prevPages.findIndex((p) => p.id === id);
      if (pageIndex !== -1) {
        const newPages = [...prevPages];
        newPages[pageIndex] = {
          ...prevPages[pageIndex],
          htmlContent: html,
          cssContent: css,
          headContent: headContent,
          components: components,
          styles: styles,
        };
        return newPages;
      }
      return prevPages;
    });
  };

  const goBackToTemplates = () => {
    setCurrentMode('selectTemplate');
    setPages([]);
    setSelectedTemplate(null);
    setSelectedPageId(null);
    toast.info('Returned to template selection.');
  };

  const handleDeleteTemplate = () => {
    openDeleteModal();
  };

  const confirmDeleteTemplate = async () => {
    if (!selectedTemplate || selectedTemplate.type !== 'user') {
      toast.error('You can only delete your own templates.');
      closeDeleteModal();
      return;
    }

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('Token not found in localStorage');
        toast.error('You need to be logged in to delete templates.');
        return;
      }

      await axios.delete(
        `${backendBaseUrl}/api/user-templates/${selectedTemplate._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success('Template deleted successfully.');
      // Refresh the user templates
      const fetchResponse = await axios.get(`${backendBaseUrl}/api/user-templates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserTemplates(fetchResponse.data);
      // Go back to template selection
      goBackToTemplates();
    } catch (error) {
      console.error('Error deleting template:', error);
      toast.error('Failed to delete template.');
    } finally {
      closeDeleteModal();
    }
  };

  const handleDeployInputChange = (e) => {
    const { name, value } = e.target;
    setDeployData((prevData) => ({ ...prevData, [name]: value }));
  };

  const deployTemplate = async () => {
    const { ipAddress, username, password, osVersion, templateName } = deployData;

    // Validation
    const ipRegex = /^(25[0-5]|2[0-4]\d|[01]?\d\d?)\.((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){2}(25[0-5]|2[0-4]\d|[01]?\d\d?)$/;
    if (
      !ipAddress ||
      !username ||
      !password ||
      !templateName ||
      templateName.trim() === ''
    ) {
      toast.error('Please fill in all fields, including Template Name.');
      return;
    }
    if (!ipRegex.test(ipAddress)) {
      toast.error('Please enter a valid IP address.');
      return;
    }

    setIsDeploying(true);

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('Token not found in localStorage');
        toast.error('You need to be logged in to deploy templates.');
        return;
      }

      // Prepare pages data to send to backend
      const pagesToDeploy = pages.map((page, index) => ({
        name: page.name,
        htmlContent: page.htmlContent,
        headContent: page.headContent || '',
        cssContent: page.cssContent,
        isHidden: page.isHidden || false,
        sequence: index,
      }));

      await axios.post(
        `${backendBaseUrl}/api/pagebuilder/deploy`,
        {
          ipAddress,
          sshUser: username,
          sshPassword: password,
          osVersion,
          pages: pagesToDeploy,
          templateName: templateName.trim(),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      toast.success('Deployment initiated successfully.');
      toast.info('Check Servers V2 Page To See Installation Status');
      closeDeployModal();
    } catch (error) {
      console.error('Deployment error:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Failed to initiate deployment: ${error.response.data.error}`);
      } else {
        toast.error('Failed to initiate deployment.');
      }
    } finally {
      setIsDeploying(false);
    }
  };

  const selectedPage = pages.find((page) => page.id === selectedPageId);

  return (
    <div className={styles.pageBuilder}>
      <Sidebar />
      <main
        className={`${styles.mainContent} ${
          isSidebarOpen ? styles.mainContentExpanded : ''
        }`}
      >
        {currentMode === 'selectTemplate' && (
          <TemplateSelection
            userTemplates={userTemplates}
            selectUserTemplate={selectUserTemplate}
            createBlankTemplate={createBlankTemplate}
            userSettings={userSettings}
          />
        )}

        {currentMode === 'editPage' && (
          <div className={styles.mainContentWrapper}>
            <EditorHeader
              goBackToTemplates={goBackToTemplates}
              handleSaveTemplate={() => {
                setSaveTemplateName(selectedTemplate?.name || 'Untitled Template');
                openSaveModal();
              }}
              isSavingTemplate={isSavingTemplate}
              openDeployModal={openDeployModal}
              selectedTemplate={selectedTemplate}
              handleDeleteTemplate={handleDeleteTemplate}
            />
            <div className={styles.editorContent}>
              <PageList
                pages={pages}
                selectPage={selectPage}
                selectedPageId={selectedPageId}
                updatePages={setPages}
                openCreatePageModal={openCreatePageModal}
              />
              <div className={styles.editorContainer}>
                {isLoadingTemplate ? (
                  null
                ) : selectedPage ? (
                  <GrapesJSEditor
                    page={selectedPage}
                    updatePageContent={updatePageContent}
                    pages={pages}
                    selectPage={selectPage}
                    headContent={selectedPage.headContent}
                  />
                ) : (
                  <div className={styles.selectPageMessage}>
                    Please select a page to edit.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </main>

      {/* Modals */}
      <CreatePageModal
        isOpen={isCreatePageModalOpen}
        onClose={closeCreatePageModal}
        pages={pages}
        onCreatePage={handleCreateNewPage}
      />
      <SaveTemplateModal
        isOpen={isSaveModalOpen}
        onClose={closeSaveModal}
        defaultTemplateName={saveTemplateName}
        onSaveTemplate={(templateName) => {
          const nameExists = userTemplates.some(
            (template) =>
              template.name.toLowerCase() === templateName.trim().toLowerCase()
          );

          if (nameExists) {
            setSaveTemplateName(templateName);
            setConfirmOverwriteOpen(true);
          } else {
            handleSaveTemplate(templateName);
          }
        }}
      />
      <ConfirmOverwriteModal
        isOpen={isConfirmOverwriteOpen}
        onClose={() => setConfirmOverwriteOpen(false)}
        templateName={saveTemplateName}
        onConfirmOverwrite={proceedWithSave}
      />
      <DeploymentModal
        isOpen={isDeployModalOpen}
        onClose={closeDeployModal}
        deployData={deployData}
        onDeployInputChange={handleDeployInputChange}
        onDeployTemplate={deployTemplate}
        isDeploying={isDeploying}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirmDelete={confirmDeleteTemplate}
      />
    </div>
  );
};

export default PageBuilder;
