// frontend/src/components/PageBuilder/modals/ConfirmOverwriteModal.js

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import styles from './ConfirmOverwriteModal.module.css';

const ConfirmOverwriteModal = ({
  isOpen,
  onClose,
  templateName,
  onConfirmOverwrite,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        className={styles.modalContent}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#2c2c2c',
          color: '#ffffff',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2 style={{ color: '#ffffff' }}>Overwrite Template</h2>
        <Typography>
          A template named "<strong>{templateName}</strong>" already exists. Do you want
          to overwrite it?
        </Typography>
        <div className={styles.modalActions} style={{ marginTop: '20px' }}>
          <Button variant="contained" color="primary" onClick={onConfirmOverwrite}>
            Yes, Overwrite
          </Button>
          <Button variant="contained" onClick={onClose} color="error">
            No, Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmOverwriteModal;
