// frontend/src/components/PageBuilder/GrapesJSEditor.js

import React, { useEffect, useRef, useMemo } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';
import styles from './GrapesJSEditor.module.css';
import { toast } from 'react-toastify';

const GrapesJSEditor = ({ page, updatePageContent, pages, headContent }) => {
  const editorRef = useRef(null);
  const updatePageContentRef = useRef(updatePageContent);

  // Update the ref when updatePageContent prop changes
  useEffect(() => {
    updatePageContentRef.current = updatePageContent;
  }, [updatePageContent]);

  // Memoize pages to prevent unnecessary re-renders
  const memoizedPages = useMemo(() => pages, [pages]);

  // Function to define custom traits for buttons
  const getButtonTraits = useMemo(
    () => [
      {
        type: 'select',
        label: 'Action',
        name: 'data-action',
        options: [
          { value: '', name: 'None' },
          { value: 'validate-and-next', name: 'Validate and Go to Next Page' },
          { value: 'go-to-next-page', name: 'Go to Next Page' },
          { value: 'go-to-specific-page', name: 'Go to Specific Page' },
        ],
      },
      {
        type: 'select',
        label: 'Page Name',
        name: 'data-page-name',
        options: memoizedPages.map((p) => ({ value: p.name, name: p.name })),
        visible: (trait, component) => {
          return component.getAttributes()['data-action'] === 'go-to-specific-page';
        },
      },
      {
        type: 'text',
        label: 'Error Message',
        name: 'data-error-message',
        placeholder: 'Enter error message',
        visible: (trait, component) => {
          const action = component.getAttributes()['data-action'];
          return action === 'validate-and-next';
        },
      },
    ],
    [memoizedPages]
  );

  // Cleanup function to destroy the editor when the component unmounts or the page changes
  useEffect(() => {
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [page.id]);

  // Utility function to pre-process HTML content
  const preprocessHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const iframes = doc.querySelectorAll('iframe');

    // Initialize a variable to collect all embedded styles
    let allEmbeddedStyles = '';

    iframes.forEach((iframe) => {
      const srcdoc = iframe.getAttribute('srcdoc') || '';
      const parserSrcdoc = new DOMParser();
      const srcdocDoc = parserSrcdoc.parseFromString(srcdoc, 'text/html');

      // Extract styles from the embedded content
      const styleTags = srcdocDoc.querySelectorAll('style');
      let embeddedStyles = '';
      styleTags.forEach((styleTag) => {
        embeddedStyles += styleTag.innerHTML + '\n';
      });

      // Collect all embedded styles
      allEmbeddedStyles += embeddedStyles;

      // Extract body content
      const bodyContent = srcdocDoc.body
        ? srcdocDoc.body.innerHTML
        : srcdocDoc.documentElement.innerHTML;

      // Create wrapper div
      const wrapperDiv = doc.createElement('div');
      wrapperDiv.setAttribute('data-type', 'iframe-container');
      wrapperDiv.setAttribute(
        'style',
        'border: padding: 10px; width: 100%; height: 400px; overflow: auto;'
      );
      wrapperDiv.innerHTML = bodyContent;

      // Replace iframe with wrapperDiv
      iframe.parentNode.replaceChild(wrapperDiv, iframe);
    });

    // Return the processed HTML and collected styles
    return { html: doc.body.innerHTML, styles: allEmbeddedStyles };
  };

  // Function to define custom components
  const customComponents = (editor) => {
    // Define 'iframe-container' component
    editor.DomComponents.addType('iframe-container', {
      isComponent(el) {
        return (
          el.tagName === 'DIV' && el.getAttribute('data-type') === 'iframe-container'
        );
      },
      model: {
        defaults: {
          tagName: 'div',
          draggable: 'body, body *',
          droppable: true, // Allow adding child components
          copyable: true,
          editable: true, // Make the container editable
          attributes: {
            'data-type': 'iframe-container',
            style:
              'border-radius: 4px; padding: 10px; width: 100%; height: 400px; overflow: auto;',
          },
          traits: [
            {
              type: 'textarea',
              label: 'HTML Content',
              name: 'innerHTML',
              changeProp: 1,
              placeholder: 'Enter HTML content here...',
            },
          ],
          components: '', // Will be populated from the provided HTML content
        },
        init() {
          this.on('change:innerHTML', this.updateContent);
          if (this.get('innerHTML')) {
            this.updateContent();
          }
        },
        updateContent() {
          const html = this.get('innerHTML') || '';
          this.components(html); // Correctly parse and set components
        },
      },
      view: {
        onRender() {
          // No need to manually set innerHTML here as components are handled by the model
        },
      },
    });

    // Define 'label' component
    editor.DomComponents.addType('label', {
      isComponent(el) {
        if (
          el.tagName === 'LABEL' ||
          (typeof el.getAttribute === 'function' && el.getAttribute('data-type') === 'label')
        ) {
          return { type: 'label' };
        }
        return false;
      },
      model: {
        defaults: {
          tagName: 'label',
          draggable: true,
          droppable: false,
          copyable: true,
          editable: true, // Allow the label to be editable
          attributes: {
            'data-type': 'label', // Custom attribute to identify this component
            labelText: '', // Include labelText in attributes
            for: '',
          },
          traits: [
            {
              type: 'text',
              label: 'Text',
              name: 'labelText',
              changeProp: 1, // Bind trait to model property
            },
            {
              type: 'text',
              label: 'For',
              name: 'for',
            },
          ],
        },
        init() {
          // Initialize 'labelText' from attributes if available
          const attrs = this.getAttributes();
          if (attrs.labelText) {
            this.set('labelText', attrs.labelText);
          } else if (!this.get('labelText')) {
            const text = this.get('content') || '';
            this.set('labelText', text);
          }

          // Listen to changes in 'labelText' property
          this.on('change:labelText', this.updateContent);

          // Update the content
          this.updateContent();
        },
        updateContent() {
          const text = this.get('labelText') || '';
          // Update the component's content
          this.set('content', text);
          // Update the attribute to ensure it's saved
          this.addAttributes({ labelText: text });
        },
      },
    });

    // Define 'input' component
    editor.DomComponents.addType('input', {
      isComponent(el) {
        if (
          el.tagName === 'INPUT' ||
          (typeof el.getAttribute === 'function' && el.getAttribute('data-type') === 'input')
        ) {
          return { type: 'input' };
        }
        return false;
      },
      model: {
        defaults: {
          tagName: 'input',
          draggable: 'form, form *',
          droppable: false,
          copyable: true,
          editable: false, // Inputs don't have inner content to edit
          attributes: {
            'data-type': 'input',
            type: 'text',
            name: '',
            placeholder: '',
            'data-format': '',
            'data-phone-format-mode': '',
            'data-region-code': '',
            'data-custom-format': '',
            'data-maxlength': '',
            'data-minlength': '',
            required: false,
          },
          traits: [
            'name',
            'placeholder',
            {
              type: 'select',
              label: 'Type',
              name: 'inputType',
              options: [
                { value: 'text', name: 'Text' },
                { value: 'password', name: 'Password' },
                { value: 'email', name: 'Email' },
                { value: 'tel', name: 'Telephone' },
                { value: 'number', name: 'Number' },
              ],
              changeProp: 1,
            },
            {
              type: 'checkbox',
              label: 'Required',
              name: 'required',
            },
            {
              type: 'number',
              label: 'Max Length',
              name: 'data-maxlength',
              placeholder: 'Enter maximum length',
              min: 1,
            },
            {
              type: 'number',
              label: 'Min Length',
              name: 'data-minlength',
              placeholder: 'Enter minimum length',
              min: 1,
            },
            {
              type: 'select',
              label: 'Format',
              name: 'data-format',
              options: [
                { value: '', name: 'None' },
                { value: 'digits', name: 'Digits Only' },
                { value: 'letters', name: 'Letters Only' },
                { value: 'email', name: 'Email' },
                { value: 'phone', name: 'Phone Number' },
                { value: 'credit-card', name: 'Credit Card' },
                { value: 'expiry-date', name: 'Expiry Date' },
                { value: 'cvv', name: 'CVV' },
                { value: 'custom', name: 'Custom' },
              ],
              changeProp: 1,
            },
            {
              type: 'select',
              label: 'Phone Format Mode',
              name: 'data-phone-format-mode',
              options: [
                { value: 'auto-detect', name: 'Auto-Detect Country' },
                { value: 'specify-region', name: 'Specify Region Code' },
              ],
              changeProp: 1,
              visible: (trait, component) => {
                return component.get('data-format') === 'phone';
              },
            },
            {
              type: 'text',
              label: 'Region Code',
              name: 'data-region-code',
              placeholder: 'e.g., US, GB, DE',
              changeProp: 1,
              visible: (trait, component) => {
                return (
                  component.get('data-format') === 'phone' &&
                  component.get('data-phone-format-mode') === 'specify-region'
                );
              },
            },
            {
              type: 'text',
              label: 'Custom Format',
              name: 'data-custom-format',
              placeholder: 'e.g. 99/99',
              changeProp: 1,
              visible: (trait, component) => {
                return component.get('data-format') === 'custom';
              },
            },
          ],
        },

        init() {
          // Initialize properties from attributes if not already set
          [
            'inputType',
            'data-format',
            'data-phone-format-mode',
            'data-region-code',
            'data-custom-format',
            'data-maxlength',
            'data-minlength',
            'required',
          ].forEach((prop) => {
            if (this.get(prop) === undefined) {
              const attrValue = this.getAttributes()[prop] || (prop === 'required' ? false : '');
              this.set(prop, attrValue);
            }
          });

          // Listen to changes in properties to update attributes
          this.listenTo(this, 'change:inputType', this.updateType);
          this.listenTo(this, 'change:data-maxlength', this.updateMaxlength);
          this.listenTo(this, 'change:data-minlength', this.updateMinlength);
          this.listenTo(this, 'change:required', this.updateRequired);
          this.listenTo(this, 'change:data-format', this.updateFormat);
          this.listenTo(this, 'change:data-custom-format', this.updateFormat);
          this.listenTo(this, 'change:data-region-code', this.updateFormat);
          this.listenTo(this, 'change:data-phone-format-mode', this.updateFormat);
        },

        updateType() {
          const inputType = this.get('inputType') || 'text';
          this.addAttributes({ type: inputType });
        },

        updateMaxlength() {
          const maxlength = this.get('data-maxlength');
          if (maxlength) {
            this.addAttributes({ maxlength });
          } else {
            this.removeAttributes(['maxlength']);
          }
        },

        updateMinlength() {
          const minlength = this.get('data-minlength');
          if (minlength) {
            this.addAttributes({ minlength });
          } else {
            this.removeAttributes(['minlength']);
          }
        },

        updateRequired() {
          const required = this.get('required');
          if (required) {
            this.addAttributes({ required: true });
          } else {
            this.removeAttributes(['required']);
          }
        },

        updateFormat() {
          const format = this.get('data-format');
          const customFormat = this.get('data-custom-format');
          const regionCode = this.get('data-region-code');
          const phoneFormatMode = this.get('data-phone-format-mode');

          // Get current attributes
          const attrs = this.getAttributes();

          // Preserve existing classes and split them into an array
          const existingClasses = attrs.class ? attrs.class.split(' ') : [];

          // Define format-specific classes to manage them separately
          const formatSpecificClasses = ['cleave-expiry-date'];

          // Remove any existing format-specific classes
          const filteredClasses = existingClasses.filter(
            (cls) => !formatSpecificClasses.includes(cls)
          );

          // Initialize a new classes array with non-format-specific classes
          let newClasses = [...filteredClasses];

          // Remove specific attributes related to formatting, but avoid removing 'class' or 'style'
          this.removeAttributes([
            'pattern',
            'maxlength',
            'minlength',
            'data-custom-format',
            'data-phone-format-mode',
            'data-region-code',
            'placeholder',
            'type', // We'll handle 'type' carefully below
          ]);

          // Handle each format case
          if (format === 'digits') {
            this.addAttributes({ 'data-format': 'digits', pattern: '\\d*' });
          } else if (format === 'letters') {
            this.addAttributes({ 'data-format': 'letters', pattern: '[a-zA-Z]*' });
          } else if (format === 'email') {
            this.addAttributes({ 'data-format': 'email' });
            // Only set type to 'email' if it's not already set to 'email'
            if (attrs.type !== 'email') {
              this.set('inputType', 'email');
              this.addAttributes({ type: 'email' });
            }
          } else if (format === 'phone') {
            this.addAttributes({ 'data-format': 'phone' });
            if (phoneFormatMode === 'specify-region' && regionCode) {
              this.addAttributes({
                'data-phone-format-mode': 'specify-region',
                'data-region-code': regionCode,
              });
            } else {
              this.addAttributes({ 'data-phone-format-mode': 'auto-detect' });
              this.removeAttributes(['data-region-code']);
            }
            // Ensure input type is 'tel' for phone formats
            if (attrs.type !== 'tel') {
              this.set('inputType', 'tel');
              this.addAttributes({ type: 'tel' });
            }
          } else if (format === 'credit-card') {
            this.addAttributes({ 'data-format': 'credit-card' });
            // Ensure input type is 'text' for credit card
            if (attrs.type !== 'text') {
              this.set('inputType', 'text');
              this.addAttributes({ type: 'text' });
            }
          } else if (format === 'expiry-date') {
            this.addAttributes({
              'data-format': 'expiry-date',
              placeholder: 'MM/YY',
              maxlength: '5',
              minlength: '5',
            });
            // Add format-specific class for Cleave.js
            newClasses.push('cleave-expiry-date');
            // Ensure input type is 'text' for expiry date
            if (attrs.type !== 'text') {
              this.set('inputType', 'text');
              this.addAttributes({ type: 'text' });
            }
          } else if (format === 'cvv') {
            this.addAttributes({
              'data-format': 'cvv',
              pattern: '\\d{3,4}',
              maxlength: '4',
              minlength: '3',
            });
            // Ensure input type is 'text' for CVV
            if (attrs.type !== 'text') {
              this.set('inputType', 'text');
              this.addAttributes({ type: 'text' });
            }
          } else if (format === 'custom' && customFormat) {
            this.addAttributes({
              'data-format': 'custom',
              'data-custom-format': customFormat,
            });
            // Ensure input type is 'text' for custom formats
            if (attrs.type !== 'text') {
              this.set('inputType', 'text');
              this.addAttributes({ type: 'text' });
            }
          } else {
            // Format is 'None' or empty
            this.removeAttributes(['data-format']);
          }

          // Update the class attribute with the new classes
          if (newClasses.length > 0) {
            this.addAttributes({ class: newClasses.join(' ') });
          } else {
            this.removeAttributes(['class']);
          }
        },
      },
    });

    // Define 'button' component
    editor.DomComponents.addType('button', {
      isComponent(el) {
        if (
          el.tagName === 'BUTTON' ||
          (typeof el.getAttribute === 'function' && el.getAttribute('data-type') === 'button')
        ) {
          return { type: 'button' };
        }
        return false;
      },
      model: {
        defaults: {
          tagName: 'button',
          draggable: 'form, form *',
          droppable: false, // Prevent adding child components
          copyable: true,
          editable: true,
          attributes: {
            'data-type': 'button',
            type: 'button',
            'data-action': '',
          },
          traits: [
            ...getButtonTraits,
            {
              type: 'text',
              label: 'Text',
              name: 'text',
              changeProp: 1,
            },
          ],
          components: [
            {
              type: 'textnode',
              content: 'Click Me', // Set default text
            },
          ],
        },
        init() {
          // Initialize 'text' property from the component's child text nodes
          const components = this.get('components');
          let initialText = '';

          components.each((child) => {
            if (child.is('textnode')) {
              initialText += child.get('content');
            }
          });

          this.set('text', initialText, { silent: true });

          // Listen to changes in 'text' property to update 'components'
          this.on('change:text', this.handleTextChange);

          // Handle 'data-action' attribute changes
          this.on('change:attributes:data-action', this.updateOnClick);
          this.updateOnClick();
        },
        handleTextChange() {
          const newText = this.get('text') || '';
          // Set the 'components' to a single textnode with 'newText'
          this.components([
            {
              type: 'textnode',
              content: newText,
            },
          ]);
        },
        updateOnClick() {
          const action = this.getAttributes()['data-action'];
          if (
            action === 'validate-and-next' ||
            action === 'go-to-next-page' ||
            action === 'go-to-specific-page'
          ) {
            // Handle click actions as needed
            // Note: Actual event handling should be implemented in the React component
          } else {
            this.removeAttributes(['onclick']);
          }
        },
      },
    });

    // Define 'form' component
    editor.DomComponents.addType('form', {
      isComponent(el) {
        if (
          el.tagName === 'FORM' ||
          (typeof el.getAttribute === 'function' && el.getAttribute('data-type') === 'form')
        ) {
          return { type: 'form' };
        }
        return false;
      },
      model: {
        defaults: {
          tagName: 'form',
          draggable: 'body, body *',
          droppable: true,
          copyable: true,
          editable: true,
          attributes: {
            'data-type': 'form',
            action: '/submit-form',
            method: 'POST',
          },
          traits: [
            {
              type: 'text',
              label: 'Action',
              name: 'action',
              placeholder: '/submit-form',
            },
            {
              type: 'select',
              label: 'Method',
              name: 'method',
              options: [
                { value: 'GET', name: 'GET' },
                { value: 'POST', name: 'POST' },
                { value: 'PUT', name: 'PUT' },
                { value: 'DELETE', name: 'DELETE' },
              ],
            },
          ],
          components: [
            {
              type: 'label',
              attributes: { for: '' },
              components: [
                {
                  type: 'textnode',
                  content: 'Label',
                },
              ],
            },
            {
              type: 'input',
              attributes: { type: 'text', placeholder: 'Enter text' },
            },
            {
              type: 'button',
              attributes: { type: 'submit' },
              traits: [
                {
                  type: 'text',
                  label: 'Text',
                  name: 'text',
                  changeProp: 1,
                },
              ],
              components: [
                {
                  type: 'textnode',
                  content: 'Submit',
                },
              ],
            },
          ],
        },
      },
    });

    // Add custom block for 'iframe-container'
    editor.BlockManager.add('iframe-container', {
      label: 'Iframe Container',
      category: 'Basic',
      attributes: { class: 'fa fa-code' },
      content: {
        type: 'iframe-container',
        attributes: {
          'data-type': 'iframe-container',
          style:
            'border-radius: 4px; padding: 10px; width: 100%; height: 400px; overflow: auto;',
        },
        traits: [
          {
            type: 'textarea',
            label: 'HTML Content',
            name: 'innerHTML',
            placeholder: 'Enter HTML content here...',
          },
        ],
        components: `
          <h2>Embedded Webpage</h2>
          <p>This content is embedded directly without an iframe.</p>
          <a href='#' class='button'>Click Me</a>
        `,
      },
    });

    // Add custom blocks for 'input', 'button', 'label', 'form'
    editor.BlockManager.add('input', {
      label: 'Input',
      category: 'Basic',
      attributes: { class: 'fa fa-keyboard-o' },
      content: {
        type: 'input',
        attributes: { type: 'text', placeholder: 'Enter text' },
      },
    });

    editor.BlockManager.add('button', {
      label: 'Button',
      category: 'Basic',
      attributes: { class: 'fa fa-square-o' },
      content: {
        type: 'button',
        traits: [
          {
            type: 'text',
            label: 'Text',
            name: 'text',
            changeProp: 1,
          },
        ],
        components: [
          {
            type: 'textnode',
            content: 'Click Me', // Set default text
          },
        ],
      },
    });

    editor.BlockManager.add('label', {
      label: 'Label',
      category: 'Basic',
      attributes: { class: 'fa fa-tag' },
      content: {
        type: 'label',
        components: [
          {
            type: 'textnode',
            content: 'Editable Label', // Set initial label text
          },
        ],
        attributes: {
          for: '', // Initialize 'for' attribute if needed
        },
      },
    });

    editor.BlockManager.add('form', {
      label: 'Form',
      category: 'Basic',
      attributes: { class: 'fa fa-list-alt' },
      content: {
        type: 'form',
        components: [
          {
            type: 'label',
            attributes: { for: '' },
            components: [
              {
                type: 'textnode',
                content: 'Label',
              },
            ],
          },
          {
            type: 'input',
            attributes: { type: 'text', placeholder: 'Enter text' },
          },
          {
            type: 'button',
            attributes: { type: 'submit' },
            traits: [
              {
                type: 'text',
                label: 'Text',
                name: 'text',
                changeProp: 1,
              },
            ],
            components: [
              {
                type: 'textnode',
                content: 'Submit',
              },
            ],
          },
        ],
      },
    });

    // Make default components editable
    editor.DomComponents.getTypes().forEach((type) => {
      const model = type.model;
      if (
        model.prototype.defaults &&
        (model.prototype.defaults.tagName === 'div' ||
          model.prototype.defaults.type === 'text' ||
          model.prototype.defaults.type === 'default' ||
          model.prototype.defaults.type === 'heading')
      ) {
        model.prototype.defaults.editable = true;
      }
    });
  };

  // Initialize the editor only once
  useEffect(() => {
    if (editorRef.current) {
      return;
    }

    try {
      // Initialize the editor
      const editor = grapesjs.init({
        container: '#gjs',
        height: '100%',
        width: 'auto',
        storageManager: false,
        components: '', // Start with empty components
        style: '', // Start with empty styles
        allowScripts: true, // Enable scripts in the editor
        parser: {
          html: {
            allowScripts: true,
            allowStyle: true,
            allowTags: true,
            allowAttrs: true,
            allowUnsafeAttr: true,
            allowEmpty: true,
            keepInlineStyles: true,
            attributes: {
              '*': [
                'data-type',
                'src',
                'srcdoc',
                'width',
                'height',
                'frameborder',
                'allowfullscreen',
                'style',
                'class',
                'id',
                'name',
                'loading',
                'title',
                'for',
                'type',
                'placeholder',
                'required',
                'data-action',
                'data-page-name',
                'data-error-message',
                'data-format',
                'data-phone-format-mode',
                'data-region-code',
                'data-custom-format',
                'data-maxlength',
                'data-minlength',
                'data-collect',
                'data-identifier',
                'innerHTML',
              ],
            },
            tags: {
              // Allow 'style' tag
              style: {
                attrs: '*',
              },
            },
          },
        },
        plugins: [grapesjsPresetWebpage], // Removed customComponents from plugins
        pluginsOpts: {
          grapesjsPresetWebpage: {},
          // Note: customComponents does not require options
        },
        fromElement: false,
        // Configure the panels
        panels: {
          defaults: [
            {
              id: 'layers',
              el: '.panel__right',
              resizable: {
                tc: 0,
                cl: 1,
                cr: 0,
                bc: 0,
                keyWidth: 'flex-basis',
              },
            },
            {
              id: 'panel-switcher',
              el: '.panel__switcher',
              buttons: [
                {
                  id: 'show-layers',
                  active: true,
                  label: 'Layers',
                  command: 'show-layers',
                  togglable: false,
                },
                {
                  id: 'show-styles',
                  active: false,
                  label: 'Styles',
                  command: 'show-styles',
                  togglable: false,
                },
                {
                  id: 'show-traits',
                  active: false,
                  label: 'Traits',
                  command: 'show-traits',
                  togglable: false,
                },
              ],
            },
            {
              id: 'panel-devices',
              el: '.panel__devices',
              buttons: [
                {
                  id: 'set-device-desktop',
                  label: 'Desktop',
                  command: 'set-device-desktop',
                  active: true,
                  togglable: false,
                },
                {
                  id: 'set-device-mobile',
                  label: 'Mobile',
                  command: 'set-device-mobile',
                  togglable: false,
                },
              ],
            },
          ],
        },
        commands: {
          defaults: [
            {
              id: 'show-layers',
              run(editor) {
                editor.Panels.getButton('views', 'open-layers').set('active', 1);
              },
              stop(editor) {
                editor.Panels.getButton('views', 'open-layers').set('active', 0);
              },
            },
            {
              id: 'show-styles',
              run(editor) {
                editor.Panels.getButton('views', 'open-sm').set('active', 1);
              },
              stop(editor) {
                editor.Panels.getButton('views', 'open-sm').set('active', 0);
              },
            },
            {
              id: 'show-traits',
              run(editor) {
                editor.Panels.getButton('views', 'open-tm').set('active', 1);
              },
              stop(editor) {
                editor.Panels.getButton('views', 'open-tm').set('active', 0);
              },
            },
            {
              id: 'set-device-desktop',
              run: (editor) => editor.setDevice('Desktop'),
            },
            {
              id: 'set-device-mobile',
              run: (editor) => editor.setDevice('Mobile'),
            },
          ],
        },
      });

      editorRef.current = editor;

      // Define custom components manually
      customComponents(editor);

      // Preprocess the HTML content to replace iframes with iframe-container divs
      let processedHTML = '';
      let collectedEmbeddedStyles = '';
      if (page.htmlContent) {
        const { html, styles } = preprocessHTML(page.htmlContent);
        processedHTML = html;
        collectedEmbeddedStyles = styles;
      }

      // Load components and styles after initializing
      if (page.components) {
        try {
          const components = JSON.parse(page.components);
          editor.setComponents(components);
        } catch (error) {
          console.error('Error parsing components JSON:', error);
          editor.setComponents(processedHTML || '');
        }
      } else {
        // If components are not available, use processed HTML content
        editor.setComponents(processedHTML || '');
      }

      if (page.styles) {
        try {
          const stylesData = JSON.parse(page.styles);
          editor.setStyle(stylesData);
        } catch (error) {
          console.error('Error parsing styles JSON:', error);
          editor.setStyle(page.cssContent || '');
        }
      } else if (page.cssContent) {
        // If styles are not available, use cssContent
        editor.setStyle(page.cssContent);
      }

      // Inject headContent and append collected embedded styles once the editor is loaded
      editor.on('load', () => {
        const head = editor.Canvas.getDocument().head;
        head.innerHTML = headContent || '';
        console.log('Injecting headContent into editor:', headContent);

        if (collectedEmbeddedStyles.trim() !== '') {
          const styleElement = editor.Canvas.getDocument().createElement('style');
          styleElement.innerHTML = collectedEmbeddedStyles;
          editor.Canvas.getDocument().head.appendChild(styleElement);
          console.log('Injected embedded styles into editor:', collectedEmbeddedStyles);
        }
      });

      // Function to add traits to components
      const addTraitsToComponent = (component) => {
        // Exclude specific component types
        if (
          component.is('input') ||
          component.is('label') ||
          component.is('form') ||
          component.is('button') ||
          component.is('iframe-container') // Exclude iframe-container as it has its own traits
        )
          return;

        const traits = component.get('traits').toArray();

        // Add Action Traits
        traits.push({
          type: 'select',
          label: 'Action',
          name: 'data-action',
          options: [
            { value: '', name: 'None' },
            { value: 'go-to-next-page', name: 'Go to Next Page' },
            { value: 'go-to-specific-page', name: 'Go to Specific Page' },
            { value: 'validate-and-next', name: 'Validate and Go to Next Page' },
          ],
        });
        traits.push({
          type: 'select',
          label: 'Page Name',
          name: 'data-page-name',
          options: memoizedPages.map((p) => ({ value: p.name, name: p.name })),
          visible: (trait, component) => {
            return component.getAttributes()['data-action'] === 'go-to-specific-page';
          },
        });
        traits.push({
          type: 'text',
          label: 'Error Message',
          name: 'data-error-message',
          placeholder: 'Enter error message',
          visible: (trait, component) => {
            const action = component.getAttributes()['data-action'];
            return action === 'validate-and-next';
          },
        });

        // Add Validation Traits
        traits.push(
          ...[
            {
              type: 'checkbox',
              label: 'Required',
              name: 'data-required',
            },
            {
              type: 'number',
              label: 'Min Length',
              name: 'data-minlength',
              placeholder: 'Enter minimum length',
              min: 1,
            },
            {
              type: 'number',
              label: 'Max Length',
              name: 'data-maxlength',
              placeholder: 'Enter maximum length',
              min: 1,
            },
            // 'Format' trait is specific to input components
          ]
        );

        // Add Data Collection Traits
        traits.push(
          ...[
            {
              type: 'checkbox',
              label: 'Collect Data',
              name: 'data-collect',
            },
            {
              type: 'text',
              label: 'Identifier',
              name: 'data-identifier',
              placeholder: 'Enter identifier',
              visible: (trait, component) => {
                return component.getAttributes()['data-collect'] === 'true';
              },
            },
          ]
        );

        component.set('traits', traits);

        component.on('change:attributes:data-action', function () {
          const action = component.getAttributes()['data-action'];
          if (
            action === 'validate-and-next' ||
            action === 'go-to-next-page' ||
            action === 'go-to-specific-page'
          ) {
            // Event listener will handle click actions
          } else {
            component.removeAttributes(['onclick']);
          }
        });

        // Make the component's content editable if it can have text content
        if (
          component.is('text') ||
          component.is('label') ||
          component.is('default') ||
          component.is('heading')
        ) {
          component.set({ editable: true });
        }

        // Recursively add to child components
        component.components().each((child) => {
          addTraitsToComponent(child);
        });
      };

      // Add traits to all existing components and listen for new components
      const addTraitsToAllComponents = () => {
        if (editorRef.current) {
          const editorInstance = editorRef.current;
          const components = editorInstance.getComponents();
          components.each((component) => {
            addTraitsToComponent(component);
          });

          editorInstance.on('component:add', (component) => {
            addTraitsToComponent(component);
          });
        }
      };

      addTraitsToAllComponents();

      // Listen to changes in the editor to update page content
      const handleChange = () => {
        const editorInstance = editorRef.current;
        const html = editorInstance.getHtml();
        const css = editorInstance.getCss();
        const components = JSON.stringify(editorInstance.getComponents().toJSON());
        const styles = JSON.stringify(editorInstance.getStyle().toJSON());
        const head = editorInstance.Canvas.getDocument().head;
        const updatedHeadContent = head.innerHTML;

        // Update page content, including components and styles
        updatePageContentRef.current(
          page.id,
          html,
          css,
          updatedHeadContent,
          components,
          styles
        );
      };

      editor.on('change:changesCount', handleChange);

      // Update button traits when pages change
      const updateButtonTraits = () => {
        if (editorRef.current) {
          const editorInstance = editorRef.current;
          const components = editorInstance.getComponents();
          components.each((component) => {
            if (component.is('button')) {
              component.set('traits', [
                ...getButtonTraits,
                {
                  type: 'text',
                  label: 'Text',
                  name: 'text',
                  changeProp: 1,
                },
              ]);
            }
          });

          // Update the default traits for the button component type
          const domComponents = editorInstance.DomComponents;
          const buttonType = domComponents.getType('button');
          if (buttonType) {
            buttonType.model.prototype.defaults.traits = [
              ...getButtonTraits,
              {
                type: 'text',
                label: 'Text',
                name: 'text',
                changeProp: 1,
              },
            ];
          }
        }
      };

      updateButtonTraits();
    } catch (error) {
      console.error('Error initializing GrapesJS editor:', error);
      toast.error('Failed to initialize the editor.');
    }
  }, [page, memoizedPages, getButtonTraits, headContent]);

  // Effect to update traits when memoizedPages change
  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = editorRef.current;
      const components = editorInstance.getComponents();
      components.each((component) => {
        if (component.is('button')) {
          component.set('traits', [
            ...getButtonTraits,
            {
              type: 'text',
              label: 'Text',
              name: 'text',
              changeProp: 1,
            },
          ]);
        }
      });

      // Update the default traits for the button component type
      const domComponents = editorInstance.DomComponents;
      const buttonType = domComponents.getType('button');
      if (buttonType) {
        buttonType.model.prototype.defaults.traits = [
          ...getButtonTraits,
          {
            type: 'text',
            label: 'Text',
            name: 'text',
            changeProp: 1,
          },
        ];
      }
    }
  }, [getButtonTraits]);

  return (
    <div className={styles.editorWrapper}>
      <div id="gjs" />
    </div>
  );
};

export default GrapesJSEditor;
