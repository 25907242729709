// frontend/src/components/PageBuilder/modals/CreatePageModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import styles from './CreatePageModal.module.css';

const CreatePageModal = ({
  isOpen,
  onClose,
  pages,
  onCreatePage,
}) => {
  const [newPageName, setNewPageName] = useState('');
  const [newPageType, setNewPageType] = useState('blank');
  const [selectedFile, setSelectedFile] = useState(null);
  const [pageToCloneId, setPageToCloneId] = useState(null);
  const [newPageIsHidden, setNewPageIsHidden] = useState(false);

  useEffect(() => {
    // Reset the form when the modal opens
    if (isOpen) {
      setNewPageName('');
      setNewPageType('blank');
      setSelectedFile(null);
      setPageToCloneId(null);
      setNewPageIsHidden(false);
    }
  }, [isOpen]);

  const handleCreateNewPage = () => {
    onCreatePage({
      newPageName,
      newPageType,
      selectedFile,
      pageToCloneId,
      newPageIsHidden,
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        className={styles.modalContent}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          color: 'text.primary',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          bgcolor: '#2c2c2c',
        }}
      >
        <h2 style={{ color: '#ffffff' }}>Create New Page</h2>
        <TextField
          label="Page Name"
          value={newPageName}
          onChange={(e) => setNewPageName(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{ style: { color: '#ffffff' } }}
        />
        <div className={styles.modalOptions}>
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{ color: '#ffffff' }}>
              Page Type
            </FormLabel>
            <RadioGroup
              aria-label="page-type"
              name="page-type"
              value={newPageType}
              onChange={(e) => setNewPageType(e.target.value)}
            >
              <FormControlLabel
                value="blank"
                control={<Radio style={{ color: '#ffffff' }} />}
                label="Blank Page"
              />
              <FormControlLabel
                value="loadHtml"
                control={<Radio style={{ color: '#ffffff' }} />}
                label="Load HTML File"
              />
              <FormControlLabel
                value="clonePage"
                control={<Radio style={{ color: '#ffffff' }} />}
                label="Clone Existing Page"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {newPageType === 'loadHtml' && (
          <div className={styles.fileInput}>
            <input
              accept=".html"
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              style={{ color: '#ffffff' }}
            />
          </div>
        )}
        {newPageType === 'clonePage' && (
          <div className={styles.clonePageSelect}>
            <FormControl fullWidth>
              <FormLabel component="legend" style={{ color: '#ffffff' }}>
                Select Page to Clone
              </FormLabel>
              <Select
                labelId="clone-page-select-label"
                value={pageToCloneId}
                onChange={(e) => setPageToCloneId(e.target.value)}
                sx={{
                  color: '#ffffff',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#ffffff',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#ffffff',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#ffffff',
                  },
                  '.MuiSvgIcon-root': {
                    color: '#ffffff',
                  },
                  backgroundColor: '#2c2c2c',
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={newPageIsHidden}
              onChange={(e) => setNewPageIsHidden(e.target.checked)}
              name="hiddenPage"
              style={{ color: '#ffffff' }}
            />
          }
          label="Hidden Page"
          style={{ color: '#ffffff' }}
        />
        <div className={styles.modalActions}>
          <Button variant="contained" color="primary" onClick={handleCreateNewPage}>
            Create
          </Button>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CreatePageModal;
