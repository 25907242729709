import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useContext } from 'react';
import SidebarContext from '../../contexts/SidebarContext';
import styles from './Guide.module.css';

const Guide = () => {
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div className={styles.guide}>
      <Sidebar />
      <main className={`${styles.guideMainContent} ${!isSidebarOpen ? styles.guideMainContentExpanded : ''}`}>
        <div className={styles.guideContainer}>
          <h1 className={styles.guideHeaderTitle}>Guide</h1>

          <section className={styles.guideSection}>
            <h2 className={styles.guideSectionTitle}>Getting Started</h2>

            <h3 className={styles.guideSubTitle}>1. Installation</h3>
            <p className={styles.guideText}>
              To install the Guide component, follow these steps:
              <ul className={styles.guideList}>
                <li className={styles.guideListItem}>Ensure you have Node.js installed.</li>
                <li className={styles.guideListItem}>Clone the repository.</li>
                <li className={styles.guideListItem}>Run <code>npm install</code> to install dependencies.</li>
              </ul>
            </p>

            <h3 className={styles.guideSubTitle}>2. Configuration</h3>
            <p className={styles.guideText}>
              After installation, configure the Guide component by editing the <code>Guide.jsx</code> file. Customize the content as needed to fit your application's requirements.
            </p>
          </section>

          <section className={styles.guideSection}>
            <h2 className={styles.guideSectionTitle}>Features</h2>

            <h3 className={styles.guideSubTitle}>1. Easy Integration</h3>
            <p className={styles.guideText}>
              The Guide component is designed for easy integration into your existing React application. Simply import and include it in your routes.
            </p>

            <h3 className={styles.guideSubTitle}>2. Customizable Layout</h3>
            <p className={styles.guideText}>
              Customize the layout and styling of the Guide component using the accompanying CSS module. Adjust colors, fonts, and spacing to match your application's theme.
            </p>

            <h3 className={styles.guideSubTitle}>3. Responsive Design</h3>
            <p className={styles.guideText}>
              The Guide component is fully responsive, ensuring a seamless experience across all devices and screen sizes.
            </p>
          </section>

          <section className={styles.guideSection}>
            <h2 className={styles.guideSectionTitle}>Advanced Usage</h2>

            <h3 className={styles.guideSubTitle}>1. Dynamic Content</h3>
            <p className={styles.guideText}>
              Load dynamic content into the Guide component by fetching data from an API or using context providers. This allows for real-time updates and personalized guides.
            </p>

            <h3 className={styles.guideSubTitle}>2. Theming</h3>
            <p className={styles.guideText}>
              Implement theming support to allow users to switch between different styles or color schemes within the Guide component.
            </p>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Guide;
