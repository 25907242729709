// frontend/src/utils/removeIds.js

export const removeIdsFromHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
  
    // Select all elements with an 'id' attribute
    const elementsWithId = doc.querySelectorAll('[id]');
    elementsWithId.forEach((el) => el.removeAttribute('id'));
  
    return doc.documentElement.outerHTML;
  };