const usaBanks = [
    { name: 'bank of america', logo: '/logos/bankofamerica.png' },
    { name: 'chase', logo: '/logos/chase.png' },
    { name: 'citi', logo: '/logos/citi.png' },
    { name: 'wells fargo', logo: '/logos/wellsfargo.png' },
    { name: 'citiziens', logo: '/logos/citiziens.png' },
    // { name: 'US Bank', logo: '/logos/usbank.png' },
    { name: 'capital one', logo: '/logos/capitalone.png' },
  ];
  
  export default usaBanks;
  