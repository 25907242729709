// frontend/src/components/PageBuilder/modals/DeleteConfirmationModal.js

import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import styles from './DeleteConfirmationModal.module.css';

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirmDelete,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        className={styles.modalContent}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#1e1e1e',
          color: '#ffffff',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2 style={{ color: '#ffffff' }}>Delete Template</h2>
        <p>
          Are you sure you want to permanently delete this template? This action cannot be
          undone.
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <Button variant="contained" color="error" onClick={onConfirmDelete}>
            Delete
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
