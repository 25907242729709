// src/components/ServersV2/ServerCardV2/ServerCardV2.js

import React, { useState } from 'react';
import { FaTrash, FaClipboardList, FaLock } from 'react-icons/fa';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './ServerCardV2.module.css';

// Styled Dialog for SSL Installation
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#131a22', // Dark background color
    color: '#eceff1cf', // Light text color
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)', // Add some shadow for depth
  },
  '& .MuiTextField-root': {
    backgroundColor: '#24303F', // Darker background for input fields
    borderRadius: '4px',
    color: '#eceff1cf',
  },
  '& .MuiInputLabel-root': {
    color: '#AAB0C6', // Light gray label color
  },
  '& .MuiInputBase-root': {
    color: '#eceff1cf', // Light text color inside inputs
  },
  '& .MuiDialogTitle-root': {
    color: '#eceff1cf', // Light color for the dialog title
  },
  '& .MuiDialogContentText-root': {
    color: '#eceff1cf', // Light color for dialog content text
  },
  '& .MuiFormHelperText-root': {
    color: '#8b92a6', // Bright yellow color for better visibility
    fontSize: '0.9rem', // Slightly larger font size
  },
  '& .cancelButton': {
    backgroundColor: '#2e4053', // Custom color for Cancel button
    color: '#fff', // White text color
    borderRadius: '4px',
    textTransform: 'none',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#263845', // Darker shade on hover
    },
    '&:focus': {
      backgroundColor: '#2e4053', // Keep the original color on focus
    },
    '&:active': {
      backgroundColor: '#2e4053', // Keep the original color on active (press)
    },
  },
  '& .installButton': {
    backgroundColor: '#239adb', // Blue background color for Install SSL button
    color: '#1F2A37', // Dark text color
    borderRadius: '4px',
    textTransform: 'none',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#1F8BBE', // Darker blue on hover
    },
  },
  '& .MuiCircularProgress-root': {
    color: '#2AABEE',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'space-between', // Align Cancel to the left and Install SSL to the right
  },
}));

const ServerCardV2 = ({ server, onServerDeleted }) => {
  const { _id, templateName, ipAddress, status, uniqueVisitCount } = server;



  // States for Delete Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // States for Normal Installation Log Modal
  const [openNormalLogModal, setOpenNormalLogModal] = useState(false);
  const [isFetchingNormalLog, setIsFetchingNormalLog] = useState(false);
  const [normalLogContent, setNormalLogContent] = useState('');

  // States for SSL Installation Log Modal
  const [openSSLLogModal, setOpenSSLLogModal] = useState(false);
  const [isFetchingSSLLog, setIsFetchingSSLLog] = useState(false);
  const [sslLogContent, setSSLLogContent] = useState('');
  const [sslJobStatus, setSSLJobStatus] = useState('');

  // States for SSL Installation Modal
  const [openSslModal, setOpenSslModal] = useState(false);
  const [isInstallingSsl, setIsInstallingSsl] = useState(false);
  const [sslDomains, setSslDomains] = useState(['']);
  const [sslVpsCredentials, setSslVpsCredentials] = useState({ ip: '', username: '', password: '' });

  // Handlers for Delete Modal
  const handleDeleteOpen = () => setOpenDeleteModal(true);
  const handleDeleteClose = () => setOpenDeleteModal(false);

  // Handlers for Normal Installation Log Modal
  const handleNormalLogOpen = async () => {
    setOpenNormalLogModal(true);
    setIsFetchingNormalLog(true);
    setNormalLogContent('');

    const authToken = localStorage.getItem('token');
    if (!authToken) {
      toast.error('Authorization token not found');
      setIsFetchingNormalLog(false);
      return;
    }

    try {
      // Fetch the normal installation log
      const response = await axios.get(`/api/v2/servers/${_id}/installation-log`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (response.status === 200) {
        const { status, log, updatedAt } = response.data;
        setNormalLogContent(`Status: ${status}\nUpdated At: ${new Date(updatedAt).toLocaleString()}\n\nLog:\n${log}`);
      } else {
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Failed to fetch normal installation log:', error);
      toast.error('Failed to fetch normal installation log');
    } finally {
      setIsFetchingNormalLog(false);
    }
  };

  const handleNormalLogClose = () => {
    setOpenNormalLogModal(false);
    setNormalLogContent('');
  };

  // Handlers for SSL Installation Log Modal
  const handleSSLLogOpen = async () => {
    setOpenSSLLogModal(true);
    setIsFetchingSSLLog(true);
    setSSLLogContent('');
    setSSLJobStatus('');

    const authToken = localStorage.getItem('token');
    if (!authToken) {
      toast.error('Authorization token not found');
      setIsFetchingSSLLog(false);
      return;
    }

    try {
      // Fetch the latest SSL installation log
      const response = await axios.get(`/api/v2/servers/${_id}/ssl-log/latest`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (response.status === 200) {
        const { jobId, status: currentStatus, log } = response.data;
        setSSLJobStatus(currentStatus);

        if (currentStatus === 'Completed' || currentStatus === 'Failed') {
          setSSLLogContent(`Status: ${currentStatus}\n\nLog:\n${log}`);
        } else {
          setSSLLogContent(`SSL installation is currently ${currentStatus}.`);
        }
      } else {
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404 && data.message === 'No SSL installation logs found for this server.') {
          setSSLLogContent('No SSL installation logs found for this server.');
        } else {
          console.error('Failed to fetch SSL installation log:', error);
          toast.error('Failed to fetch SSL installation log');
        }
      } else {
        console.error('Failed to fetch SSL installation log:', error);
        toast.error('Failed to fetch SSL installation log');
      }
    } finally {
      setIsFetchingSSLLog(false);
    }
  };

  const handleSSLLogClose = () => {
    setOpenSSLLogModal(false);
    setSSLLogContent('');
    setSSLJobStatus('');
  };

  // Handlers for SSL Installation Modal
  const handleSslOpen = () => setOpenSslModal(true);
  const handleSslClose = () => {
    setOpenSslModal(false);
    setSslDomains(['']);
    setSslVpsCredentials({ ip: '', username: '', password: '' });
  };

  const handleAddSslDomain = () => setSslDomains([...sslDomains, '']);
  const handleSslDomainChange = (index, value) => {
    const newDomains = [...sslDomains];
    newDomains[index] = value;
    setSslDomains(newDomains);
  };

  const handleSslVpsCredentialChange = (field, value) => {
    setSslVpsCredentials({ ...sslVpsCredentials, [field]: value });
  };

  const isValidDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainRegex.test(domain) && !domain.includes('/') && !domain.startsWith('http') && !domain.startsWith('www');
  };

  const isValidIp = (ip) => {
    const ipRegex = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}$/;
    return ipRegex.test(ip);
  };

  // SSL Installation Handler
  const handleInstallSsl = async () => {
    // Validate Domains
    for (const domain of sslDomains) {
      if (!isValidDomain(domain)) {
        toast.error(`Invalid domain name format: ${domain}`);
        return;
      }
    }

    // Validate VPS IP
    if (!isValidIp(sslVpsCredentials.ip)) {
      toast.error('Invalid VPS IP address format.');
      return;
    }

    // Validate SSH Credentials
    if (!sslVpsCredentials.username || !sslVpsCredentials.password) {
      toast.error('SSH Username and Password are required.');
      return;
    }

    setIsInstallingSsl(true);
    try {
      const authToken = localStorage.getItem('token');
      if (!authToken) {
        toast.error('Authorization token not found');
        return;
      }

      // Prepare the payload
      const payload = {
        sshUser: sslVpsCredentials.username,
        sshPassword: sslVpsCredentials.password,
        domains: sslDomains,
      };

      // Make the POST request to install SSL
      const response = await axios.post(`/api/v2/servers/${_id}/install-ssl`, payload, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (response.status === 200) {
        const { jobId } = response.data;
        // Optionally, you can store jobId in state if needed elsewhere
        // setSSLJobId(jobId);
        // setSSLJobStatus('In Progress');
        toast.success('SSL installation started.');
        handleSslClose();
      } else {
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Failed to install SSL:', error);
      toast.error('Failed to install SSL');
    } finally {
      setIsInstallingSsl(false);
    }
  };

  // Delete (Archive) Confirmation Handler
  const handleDeleteConfirm = async () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      toast.error('Authorization token not found');
      return;
    }
    setIsDeleting(true);
    try {
      const response = await axios.delete(`/api/v2/servers/${_id}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.status === 200) {
        toast.success('Server archived successfully');
        onServerDeleted(_id);
      } else {
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Failed to archive server:', error);
      toast.error('Failed to archive server');
    } finally {
      setIsDeleting(false);
      handleDeleteClose();
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h2 className={styles.domainName}>{templateName || 'No Template Name'}</h2>
        <span className={`${styles.status} ${styles[status.toLowerCase()]}`}>{status}</span>
      </div>
      <div className={styles.content}>
        <div className={styles.details}>
          <p className={styles.detail}>
            <span className={styles.label}>IP:</span> {ipAddress}
          </p>
          <p className={styles.detail}>
            <span className={styles.label}>Visitors:</span> {uniqueVisitCount}
          </p>
        </div>
        <div className={styles.actions}>
          <button onClick={handleSslOpen} className={`${styles.actionButton} ${styles.ssl}`}>
            <FaLock className={styles.icon} /> Install SSL
          </button>
          <button onClick={handleDeleteOpen} className={`${styles.actionButton} ${styles.delete}`}>
            <FaTrash className={styles.icon} /> Archive
          </button>
          <button onClick={handleNormalLogOpen} className={`${styles.actionButton} ${styles.log}`}>
            <FaClipboardList className={styles.icon} /> Installation Log
          </button>
          <button onClick={handleSSLLogOpen} className={`${styles.actionButton} ${styles.log}`}>
            <FaClipboardList className={styles.icon} /> SSL Installation Log
          </button>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteClose}
        aria-labelledby="delete-confirmation-title"
        classes={{ paper: styles.dialogPaper }}
      >
        <DialogTitle id="delete-confirmation-title" className={styles.dialogTitle}>
          Confirm Archiving
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <DialogContentText id="delete-confirmation-description" className={styles.dialogText}>
            Are you sure you want to archive this server?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleDeleteClose} disabled={isDeleting} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} disabled={isDeleting} className={styles.deleteButton}>
            {isDeleting ? <CircularProgress size={24} className={styles.deleteSpinner} /> : 'Archive'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Normal Installation Log Modal */}
      <Dialog
        open={openNormalLogModal}
        onClose={handleNormalLogClose}
        aria-labelledby="normal-installation-log-title"
        fullWidth
        maxWidth="md"
        classes={{ paper: styles.dialogPaper }}
      >
        <DialogTitle id="normal-installation-log-title" className={styles.dialogTitle}>
          Installation Log
        </DialogTitle>
        <DialogContent className={styles.dialogContent} dividers>
          {isFetchingNormalLog ? (
            <div className={styles.loadingContainer}>
              <CircularProgress className={styles.loadingSpinner} />
              <p>Fetching installation log...</p>
            </div>
          ) : normalLogContent ? (
            <TextField
              value={normalLogContent}
              multiline
              fullWidth
              variant="outlined"
              rows={20}
              InputProps={{
                readOnly: true,
                classes: { input: styles.textField },
              }}
              className={styles.logTextField}
            />
          ) : (
            <DialogContentText className={styles.errorText}>
              Unable to retrieve installation log.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleNormalLogClose} className={styles.closeButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* SSL Installation Log Modal */}
      <Dialog
        open={openSSLLogModal}
        onClose={handleSSLLogClose}
        aria-labelledby="ssl-installation-log-title"
        fullWidth
        maxWidth="md"
        classes={{ paper: styles.dialogPaper }}
      >
        <DialogTitle id="ssl-installation-log-title" className={styles.dialogTitle}>
          SSL Installation Log
        </DialogTitle>
        <DialogContent className={styles.dialogContent} dividers>
          {isFetchingSSLLog ? (
            <div className={styles.loadingContainer}>
              <CircularProgress className={styles.loadingSpinner} />
              <p>Fetching SSL installation log...</p>
            </div>
          ) : sslLogContent ? (
            <TextField
              value={sslLogContent}
              multiline
              fullWidth
              variant="outlined"
              rows={20}
              InputProps={{
                readOnly: true,
                classes: { input: styles.textField },
              }}
              className={styles.logTextField}
            />
          ) : (
            <DialogContentText className={styles.errorText}>
              Unable to retrieve SSL installation log.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleSSLLogClose} className={styles.closeButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* SSL Installation Dialog */}
      <CustomDialog open={openSslModal} onClose={handleSslClose}>
        <DialogTitle>Install SSL</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the domain names and VPS credentials.</DialogContentText>
          {sslDomains.map((domain, index) => (
            <TextField
              key={index}
              margin="dense"
              label={`Domain ${index + 1}`}
              fullWidth
              value={domain}
              onChange={(e) => handleSslDomainChange(index, e.target.value)}
              variant="outlined"
              helperText="Format: example.com (no https://, no www, no trailing slash)"
            />
          ))}
          <Button onClick={handleAddSslDomain} className={styles.addDomainButton}>
            + New Domain
          </Button>
          <TextField
            margin="dense"
            label="VPS IP"
            fullWidth
            value={sslVpsCredentials.ip}
            onChange={(e) => handleSslVpsCredentialChange('ip', e.target.value)}
            variant="outlined"
            helperText="Format: 192.168.1.1"
          />
          <TextField
            margin="dense"
            label="Username"
            fullWidth
            value={sslVpsCredentials.username}
            onChange={(e) => handleSslVpsCredentialChange('username', e.target.value)}
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={sslVpsCredentials.password}
            onChange={(e) => handleSslVpsCredentialChange('password', e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSslClose} className="cancelButton">
            Cancel
          </Button>
          <Button onClick={handleInstallSsl} className="installButton" disabled={isInstallingSsl}>
            {isInstallingSsl ? <CircularProgress size={24} /> : 'Install SSL'}
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default ServerCardV2;
